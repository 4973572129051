import Vue from 'vue'
import Vuex from 'vuex'

import user from "@/store/user";

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    snackbar: {
      show: false,
      timeout: 3000,
      color: "success",
      msg: "",
      text: "#fff",
    },
  },
  getters: {
    snackbarOptions(state) {
      return state.snackbar;
    },
  },
  mutations: {
    SET_SNACKBAR(state, { msg, color, show }) {
      state.snackbar.msg = msg;
      state.snackbar.color = color || "success";
      state.snackbar.show = show;
    },
  },
  actions: {
    showSnackbar({ commit }, {msg, color}) {
      commit("SET_SNACKBAR", { msg, color, show: true});
    },
    closeSnackbar({ commit }) {
      commit("SET_SNACKBAR", { show: false });
    },
  },
  modules: {
    user
  }
})
