export default (axios) => ({
  list(params) {
    return axios.get("/links", { params });
  },
  details(id,params){
    return axios.get(`/links/${id}/users`, { params });
  },
  statistic(params){
    return axios.get('/dashboard', { params });
  },
  createLink(){
    return axios.post("/links/create");
  },
});
