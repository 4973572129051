import Vue from 'vue'
import store from "@/store"
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView'),
    redirect: { name: "Dashboard" },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("@/components/TheDashboard"),
      },
      {
        path: "profile",
        name: "Profile",
        component: () => import("@/components/TheProfile"),
      },
      {
        path: "details",
        name: "Details",
        component: () => import("@/components/TheDetails"),
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginView')
  },
  {
    path: '*',
    redirect: {name: 'Login'}
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
router.beforeEach(async (to,from,next) => {
  if (!store.getters["user/isAuth"] && to.name !== "Login") {
    next({ name: "Login" })
  }else {
    next()
  }
})

export default router
