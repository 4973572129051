import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import uk from "vuetify/lib/locale/uk";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { uk },
    current: "uk",
  },
  customVariables: ['./variables.scss'],
  theme: {
    themes: {
      light: {
        primary: '#141f72',
        secondary: '#24324a',
        accent: '#fb236a',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
      },
      dark: {
        primary: '#141f72',
        secondary: '#24324a',
        accent: '#fb236a',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
      },
    },
  },
});
