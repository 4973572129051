import api from "@/api";
import router from "@/router";
const TOKEN_NAME = "tema_affiliate_token"
export default {
  namespaced: true,
  state() {
    return {
      token: localStorage.getItem(TOKEN_NAME) || null,
      profile: null
    }
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem(TOKEN_NAME, token);
    },
    SET_PROFILE(state, profile) {
      state.profile = profile;
    },
  },
  getters: {
    isAuth(state) {
      return !!state.token;
    },
    token(state){
      return state.token;
    },
    profile(state){
      return state.profile
    }
  },
  actions: {
    async login({ commit, dispatch }, params) {
      try {
        const { data } = await api.user.login(params);
        commit("SET_TOKEN", data.data.token);
        await dispatch('getProfile');
        await router.push({ name: "Dashboard" });
      } catch (e) {
        console.error(e);
      }
    },
    async getProfile({ commit }){
      try {
        const { data } = await api.user.profile();
        commit("SET_PROFILE", data);
      } catch (e) {
        console.error(e?.message);
      }
    },
    async logout({ commit }) {
      commit("SET_TOKEN", null);
      commit("SET_PROFILE", null);
      await router.push({ name: "Login" });
    },
  },
};
